<script
    lang="ts"
    setup
>
    type Props = {
        content: string
        tag?: string
    }

    const props = withDefaults(defineProps<Props>(), {
        tag: 'div',
    })
</script>

<template>
    <!-- eslint-disable vue/no-v-html -->
    <component :is="props.tag">
        <span v-html="props.content"></span>
    </component>
    <!-- eslint-enable vue/no-v-html -->
</template>
